import { isNil } from "lodash";
import {removeDashesFromCreditCard} from "./helpers";

export interface IValidation {
	name: string;
    options: any
}

const findValidation = (validation: string, validations: IValidation[]) => {
	if (!validation || !validations){
		return null
	}
	return validations.find((x) => x.name === validation)
}

export const validateEmail = (email: any) => {
	const res =
	  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return res.test(String(email).toLowerCase());
  };

export enum VALIDATIONS {
	REQUIRED = 'required',
	LESS_THAN = 'less_than',
	LESS_THAN_EQUAL_TO = 'less_than_equal_to',
	MORE_THAN_EQUAL_TO = 'more_than_equal_to',	
}

export const validateAttribute = (attribute: string, val: any, validations: IValidation[], errors: any, setErrors: Function) => {
	//console.log("called?", attribute, val, validations, errors)
	//console.log("the val", val);
	let errStrings = [];
	const required = findValidation('required', validations)
	if (required && (val === '' || isNil(val))) {
		errStrings.push('Required')
	}
	const lessThanEqualTo = findValidation(VALIDATIONS.LESS_THAN_EQUAL_TO, validations)
	if (lessThanEqualTo && val > lessThanEqualTo.options.max) {
		errStrings.push('Must be less than or equal to ' + lessThanEqualTo.options.max)
	}
	const moreThanOrEqualTo = findValidation(VALIDATIONS.MORE_THAN_EQUAL_TO, validations)
	if (moreThanOrEqualTo && val < moreThanOrEqualTo.options.min) {
		errStrings.push('Must be more than or equal to ' + moreThanOrEqualTo.options.min)
	}
	let errString = ''
	if (errStrings.length) {
		errString = errStrings.join(', ')
	}
	let setVal = {
		...errors,
		[attribute]: errString
	}
	setErrors(setVal)
	if (errString !== ''){
		return {
			isValid: false,
			errors: setVal
		}
	}
	return {
		isValid: true,
		errors: setVal
	}
}

export const validateForm = (obj: any, validations: any, errors: any, setErrors: Function) => {

	let isValid = true;
	let errObj = errors;
	for (const [key, value] of Object.entries(obj)) {
		const valIsValid = validateAttribute(key, value, validations[key], errObj, setErrors);
	    errObj = valIsValid.errors
		if (!valIsValid.isValid){
			isValid = false
		}
	}
	return isValid;
}


export const isValidCardNumber = (cardNumber:string) => {
	const cardNumberOnlyNumbers = removeDashesFromCreditCard(cardNumber)
	// Implement Luhn Algorithm to check validity
	let sum = 0;
	let shouldDouble = false;
	for (let i = cardNumberOnlyNumbers.length - 1; i >= 0; i--) {
		let digit = parseInt(cardNumberOnlyNumbers.charAt(i));
		
		if (shouldDouble) {
			digit *= 2;
			if (digit > 9) {
				digit -= 9;
			}
		}
		
		sum += digit;
		shouldDouble = !shouldDouble;
	}
	
	return (sum % 10) === 0;
}