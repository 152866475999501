import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import Users from './pages/private/users/UsersPage';
import UserViewPage from './pages/private/users/UserViewPage';
import Privacy from './pages/public/privacy/PrivacyPage';
import AdminDashboard from './pages/private/dashboard/DashboardPage';
import Account from './pages/private/account/AccountPage';
import Terms from './pages/public/terms/Terms';
import ForceLogout from './components/shared/ForceLogout';
import ReleaseNotes from './pages/private/site/ReleaseNotes';
import Uploader from './components/shared/Uploader';
import VerifyEmail from './pages/private/site/VerifyEmail';
import { COLORS } from './shared/constants/layout-constants';
import GlobalSettingsPage from './components/global-settings/GlobalSettingsPage';
import { getGlobalSettings } from './store/actions/globalSettingsActions';
import { useSelector } from './store';

import NavBarMenu from "./components/general/NavbarMenu/NavbarMenu";
import BatchHistoryPage from "./pages/private/batchHistoryPage/BatchHistoryPage";
import {NotFoundPage} from "./pages/public/NotFoundPage";
import {PROTECTED_ROUTES} from "./shared/constants/app-constants";
import PageGridWrapperTemplate from "./components/general/PageGridWrapperTemple";
import PaymentPage from "./pages/private/PaymentPage/PaymentPage";


const authContStyle = {
	marginTop: 0,
	width: '100%',
	paddingLeft: '10%',
	paddingRight: '10%',
}

// @ts-ignore
const PageWrapperPageWrapper = ({children}) => <div style={authContStyle}>{children}</div>

function AppAuthenticated(props: any) {
	const dispatch = useDispatch();
	
	const [isAdmin, setIsAdmin] = useState(false);
	const [dataFetched, setDataFetched] = useState(false);
	
	const globalSettings = useSelector((state) => state.main.global_settings);
	const theme = useSelector((state) => state.main.theme);

	useEffect(() => {
		if (!globalSettings?.id) {
			dispatch(getGlobalSettings({}, () => { setDataFetched(true) }))
		}
	}, [])

	const user = useSelector(
		(state) => state.main.my_user
	);

	const user_roles = useSelector(
		(state) => state.main.user_roles
	)

	const roles = useSelector(
		(state) => state.main.roles
	)

	const clientOptions = useSelector(
		(state) => state.main.client_options
	);

	useEffect(() => {
		let userRolesArr = Object.values(user_roles);
		let rolesArr = Object.values(roles);
		if (!isAdmin && user?.id && userRolesArr.length && rolesArr.length) {

			const adminRole = rolesArr.find((x) => x.name === "Admin");
			if (adminRole) {
				const userAdminRole = userRolesArr.find((x) => x.user_id === user.id && x.role_id === adminRole.id)
				if (userAdminRole) {
					setIsAdmin(true);
				}
			}

		}
	}, [user, user_roles, roles, isAdmin])

	return (
		<div style={{
			width: '100vw',
			height: 'auto',
			backgroundColor: COLORS.SCREEN_BACKGROUND,
			color: theme.colors.font,
			overflow:"hidden"}}>
			<Box sx={{ display: 'flex' }}>
				<CssBaseline />
				< NavBarMenu isPublic={false}/>
				<Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: 8, minHeight:'100vh' }}>
					<Routes>
						<Route path={PROTECTED_ROUTES.DASHBOARD} element={<PageGridWrapperTemplate><AdminDashboard /></PageGridWrapperTemplate>} />
						<Route path={PROTECTED_ROUTES.BACTH_HISTORY} element={<BatchHistoryPage />} />
						<Route path={PROTECTED_ROUTES.PAYMENT_FORM} element={<PageGridWrapperTemplate><PaymentPage/></PageGridWrapperTemplate>} />
						<Route path="Account" element={<Account user={user} />} />
						<Route path="Users/:userId" element={<UserViewPage />} />
						<Route path="Users" element={<Users />} />
						<Route path="ForgotPassword" element={<ForceLogout />} />
						<Route path="ResetPassword" element={<ForceLogout />} />
						<Route path="Privacy" element={<Privacy />} />
						<Route path="Terms" element={<Terms />} />
						<Route path="About" element={<ReleaseNotes />} />
						<Route path="ReleaseNotes" element={<ReleaseNotes />} />
						<Route path="GlobalSettings" element={<GlobalSettingsPage key={dataFetched} />} />
						<Route path="Upload" element={<PageWrapperPageWrapper><Uploader showToast={true} /></PageWrapperPageWrapper>} />
						<Route path="VerifyEmail" element={<PageWrapperPageWrapper><VerifyEmail /></PageWrapperPageWrapper>} />
						<Route path="*" element={<PageWrapperPageWrapper ><NotFoundPage/></PageWrapperPageWrapper>} />
					</Routes>
				</Box>
			</Box>
		</div>
	)
}

export default AppAuthenticated
