import {
  Grid,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import { lazy } from 'react';
import useScreenSize from "../../../hooks/useScreenSize";
import DesktopLoginForm from "./DesktopLoginForm";
import NavBarMenu from "../../../components/general/NavbarMenu/NavbarMenu";
import SplashScreen from "../../../components/general/SplashScreen";
import {maxWidthMobileScreen} from "../../../shared/constants/layout-constants";
const MobileLoginForm = lazy(() => import('./MobileLoginForm'));
const Login = () => {
    
    const {width:screenWidth} = useScreenSize()
    const [showSplash, setShowSplash] = useState<boolean>(true)
    
    useEffect(() => {
        setTimeout(()=>{
            setShowSplash(false)
        },3000)
        
    }, []);
    
    if(screenWidth < maxWidthMobileScreen && showSplash){
        return <SplashScreen/>
    }
    

  return (
      <>
        < NavBarMenu isPublic={true}/>
        <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{
                height: "100vh"
            }}
        >
            {
                screenWidth > maxWidthMobileScreen ? <DesktopLoginForm/>:<MobileLoginForm/>
            }
        </Grid>
     </>
  );
};

export default Login;
