export const ENABLE_ORDERS = true;
export const CLIENT_APPLICATION_NAME = "ontop-web";

export enum PROTECTED_ROUTES {
  DASHBOARD = "/dashboard",
  BACTH_HISTORY = "/batch-history",
  PAYMENT_FORM = "/payment",
  PAYMENT_REQUEST_FORM = "/payment-request",
}

export enum PUBLIC_ROUTES {
  LOGIN = "/Admin",
}

export enum LOCALSTORAGE_VALUES {
  CREDENTIALS = "onTopCredentials",
}
