import {styled} from "@mui/material/styles";
import {Button} from "@mui/material";

export const LoginButton = styled(Button)({
    boxShadow: 'none',
    fontSize:'18px',
    textTransform:'capitalize',
    marginBottom:22,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    fontFamily: [
        'Arial'
    ].join(','),
    backgroundColor: '#D8010E',
    '&:hover':{
        backgroundColor:'#D8010E',
        boxShadow: 'none',
}
});