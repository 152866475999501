export const removeDashesFromCreditCard = (value:string) => value.replace(/-/g,"")

export const getExpiredDateFormat = (month:string, year:string) => {
    const monthFormatted = Number(month)<10 ? "0".concat(month) : month
    const yearFormatted = year.slice(2,4)
    return `${monthFormatted}${yearFormatted}`
}

export const formatAmount = (number:number) => {
    return number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}