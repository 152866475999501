import {Button, Paper, Stack, Typography} from "@mui/material";
import {COLORS, maxWidthMobileScreen} from "../../../shared/constants/layout-constants";
import React, {useState} from "react";
import useScreenSize from "../../../hooks/useScreenSize";
import {PROTECTED_ROUTES} from "../../../shared/constants/app-constants";
import {useNavigate} from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import {TransitionProps} from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import InfoIcon from "../../../assets/svg/ExclamationCircle.svg"


const CancelPaymentProcessDialog = () => {
    
    const {width} = useScreenSize();
    const isMobile = width < maxWidthMobileScreen
    const navigate = useNavigate()
    const [showDialog, SetShowDialog] = useState<boolean>(false)
    
    const goToDashboard = () => navigate(PROTECTED_ROUTES.DASHBOARD)
    
    const Transition = React.forwardRef(function Transition(
        props: TransitionProps & {
            children: React.ReactElement<any, any>;
        },
        ref: React.Ref<unknown>,
    ) {
        return <Slide direction="up" ref={ref} {...props} />;
    });
    
    return(
        <>
            <Button variant="text" style={{color:COLORS.BRAND_PRIMARY, marginTop:'15px',textTransform:'capitalize'}} fullWidth disableRipple onClick={()=>SetShowDialog(true)} >Cancel</Button>
            <br/>
            <Typography style={{color:'#ACACAC', fontSize:'12px',fontFamily:'NotoSans-Regular'}}>
                Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our privacy policy.
            </Typography>
            
            {
                showDialog && (<React.Fragment>
                    <Dialog
                        open={showDialog}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={()=>SetShowDialog(false)}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <Paper sx={isMobile?{}:{padding: 3}}>
                        <DialogTitle>
                            <Stack direction="row">
                                <img src={InfoIcon} alt={'information'} width={20} style={{ cursor: 'pointer', float: 'left', marginRight:'10px' }}/>
                                <Typography>
                                    Are you sure you want to cancel?
                                </Typography>
                            </Stack>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                You'll lose all your progress. You can't undo this action.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" style={{border:'1px solid #D9D9D9', color:"#D9D9D9", textTransform:"capitalize"}} onClick={ ()=>SetShowDialog(false) }>No</Button>
                            <Button variant="contained" style={{color:"#FFF", backgroundColor:"#D8010E", textTransform:"capitalize"}} onClick={goToDashboard}>yes</Button>
                        </DialogActions>
                        </Paper>
                    </Dialog>
                </React.Fragment>)
            }
        </>
    )
}

export default CancelPaymentProcessDialog