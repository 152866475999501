/* eslint-disable no-param-reassign */
import _, { isObject } from "lodash";
import { IReduceAction } from "../../interfaces/store";
import { IMainState } from "../../interfaces/store/main";
import { autoReduce } from "./shared";
import { normalize } from "normalizr";
import { roleSchema, userRoleSchema } from "../sagas/schema";
import { IUser } from "../../shared/interfaces/model/user.interface";
import { IRole } from "../../shared/interfaces/model/role.interface";
import { IUserRole } from "../../shared/interfaces/model/user_role.interface";
import { ACTIONS } from "../../enums/actions";
import { IResource } from "../../shared/interfaces/model/resource.interfaces";
import {IBatchHistoryColum} from "../../shared/interfaces/transactionHistory.interface";
import { IGlobalSettings } from "../../shared/interfaces/global-settings.interface";
import { ITheme, THEME_NAME, getThemeColors } from "../../shared/constants/layout-constants";

let setUser = {} as any;
let setUserRoles = {} as any;
let setRoles = {} as any;
let existingUser = localStorage.getItem("my_user");
let existingRoles = localStorage.getItem("roles");
let existingUserRoles = localStorage.getItem("user_roles")
let existingTimezone = localStorage.getItem("timezone");

if (!existingTimezone) {
  localStorage.setItem("timezone", "America/Chicago")
  existingTimezone = "America/Chicago"
}

// console.log("local storage user", existingUser);
const onTopCredentials = JSON.parse(localStorage.getItem('onTopCredentials') || '{}')
if (onTopCredentials)
  try {
    setUser = {
      email: onTopCredentials.cLog__LUser
    };
  } catch (e) { }

if (existingUserRoles) {
  try {
    existingUserRoles = JSON.parse(existingUserRoles)
    setUserRoles = existingUserRoles
  }
  catch (e) { }
}

if (existingRoles) {
  try {
    existingRoles = JSON.parse(existingRoles)
    setRoles = existingRoles
  }
  catch (e) { }
}

interface IPaymentStep1{
  status:boolean,
  invoiceNumber:string | undefined,
  amount: string | undefined
}

export const mainStateObject = {
  client_options: {},
  error: {} as any,
  global_settings: {} as IGlobalSettings,
  jwt_token: '',
  meta: {} as any,
  my_user: setUser as IUser,
  resources: {} as { [key: string ]: IResource },
  roles: setRoles as { [key: string]: IRole },
  success: {} as any,
  timezone: existingTimezone as string,
  user_roles: setUserRoles as { [key: string]: IUserRole },
  users: {} as { [key: string]: IUser },
  theme: {
    colors: getThemeColors(THEME_NAME.LIGHT),
    classes: {
      main: "light-theme",
    }
  } as ITheme,
  batchHistoryList: [] as IBatchHistoryColum[],
  batchHistoryRequestTypeNum: "2" as string,
  paymentStep1:{status:false} as IPaymentStep1
};

const resetState = _.cloneDeep(mainStateObject)

const initialState: IMainState = { ...mainStateObject };

const mainReducer = (
  state: any = initialState,
  action: IReduceAction
): IMainState => {


  if (action && (action.stateKey === "main" || !action.stateKey)) {
    if (action.normalized || action.removeKeys || action.overrides) {
      return autoReduce(action, state);
    }
    switch (action.type) {
      case ACTIONS.ERROR:
        if (isObject(action.payload)) {
          state.error = action.payload;
          return { ...state };
        }
        break;
      case ACTIONS.SUCCESS:
        if (isObject(action.payload)) {
          state.success = action.payload;
          return { ...state };
        }
        break;
      case "GET_USER_NOTE_SUCCESS":
        state.user_notes = action.payload;
        break;

      case ACTIONS.LOGIN_SUCCESS:
        state.my_user = action.payload.user;
        return { ...state }
      case "LOGOUT_SUCCESS":
        localStorage.removeItem("onTopCredentials");
        // eslint-disable-next-line no-restricted-globals
        window.location.href = "/Admin";
        return { ...resetState }
      case "SET_CLIENT_OPTIONS_SUCCESS":
        state.client_options = {
          ...state.client_options,
          ...action.payload,
        };
        break;
      case "UPDATE_TIMEZONE":
        state.timezone = action.payload
        break;
      case ACTIONS.SET_BATCH_HISTORY_SUCCESS:
        state.batchHistoryList = action.payload
        break;
      case ACTIONS.SET_BATCH_HISTORY_REQUEST_TYPE_NUM:
        state.batchHistoryRequestTypeNum = action.payload
        break;
      case ACTIONS.PAYMENT_STEP1:
        state.paymentStep1 = action.payload
        break;
      default:
        return state;
    }
  }
  return state;
};

export default mainReducer;
