import { Divider, TextField, Typography} from "@mui/material";
import {initialValuesStep1, typeMask, validationSchemaStep1} from "./constant";
import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {TextMaskCustom} from "./TextMaskCustom";
import {LoadingButton as NextButton} from "@mui/lab";
import {useDispatch} from "react-redux";
import {paymentStepOne} from "../../../store/actions/paymentActions";

import './payment-page.css'
import CancelPaymentProcessDialog from "./CancelPaymentProcessDialog";
import Box from "@mui/material/Box";
import {maxWidthMobileScreen} from "../../../shared/constants/layout-constants";
import useScreenSize from "../../../hooks/useScreenSize";

interface IPaymentFormStep1{
    action:Function
}
const PaymentFormStep1:React.FC<IPaymentFormStep1> = ({action}) => {
    const dispatch = useDispatch()
    
    const {width: widthScreen} = useScreenSize()
    const [amountCard, setAmountCard] = useState<string>('');
    const [amountInputFocus, setAmountInputFocus] = useState<boolean>(false)
    
    const cardStyle = widthScreen>maxWidthMobileScreen ? {minWidth:'650px',backgroundColor:'#FFF',borderRadius:'12px',padding:'20px'}:{backgroundColor:'#FFF',borderRadius:'12px',padding:'20px'}
    const handleNext = (val:any) => {
        dispatch(paymentStepOne({status:true, invoiceNumber:val.invoiceNumber, amount: val.amount} as any))
        action(true)
    }
    
    const formik = useFormik({
        initialValues: initialValuesStep1,
        validationSchema: validationSchemaStep1,
        onSubmit: handleNext
    });
    
    const handleAmountChange = async(event: any) => {
        setAmountCard(event.target.value)
    }
    
    useEffect(() => {
        formik.setFieldValue('amount', amountCard?? 0)
    }, [amountCard]);
    
    return (
        <Box sx={cardStyle}>
            <Typography variant="h3" fontWeight="bolder" style={{color:"#333333",fontSize:'20px',fontFamily:'NotoSans-Regular',marginBottom:'20px'}}>Payment Details</Typography>
            <Typography variant="h4" fontWeight="bolder" style={{color:"#333333",fontSize:'20px',fontFamily:'NotoSans-Regular',marginBottom:'16px'}} >Details</Typography>
            <Divider style={{color:"#E3E8EF",marginBottom:'12px'}}/>
            <form onSubmit={formik.handleSubmit}>
                <Typography variant='body1' fontSize={'16px'} fontWeight={600} style={{color:"#333333",fontFamily:'NotoSans-Regular',backgroundColor:'transparent',marginBottom:'-12px'}}>Invoice Number</Typography>
                
                <TextField margin="normal" key={`invoice-inputField`}
                           inputProps={{
                               'data-testid': `invoice-inputField`,
                               style: {textTransform: "uppercase"}
                           }}
                           fullWidth
                           id="invoiceNumber"
                           name="invoiceNumber"
                           value={formik.values["invoiceNumber"]}
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           error={formik.touched["invoiceNumber"] && Boolean(formik.errors["invoiceNumber"])}
                           helperText={formik.touched["invoiceNumber"] && formik.errors["invoiceNumber"]}
                />
                <Typography variant='body1' fontSize={'16px'} fontWeight={600} style={{color:"#333333",fontFamily:'NotoSans-Regular',backgroundColor:'transparent',marginBottom:'-12px'}}>Amount</Typography>
                <TextField key={`amount-inputField`}
                           inputProps={{'data-testid': `amount-inputField`}}
                           style={{marginTop:14,marginBottom:9}}
                           fullWidth
                           id={"amount"}
                           name={"amount"}
                           onChange={handleAmountChange}
                           value={formik.values["amount"]}
                           error={formik.touched["amount"] && Boolean(formik.errors["amount"])}
                           helperText={formik.touched["amount"] && formik.errors["amount"]}
                           InputProps={{
                               inputComponent: TextMaskCustom(formik, typeMask.CURRENCY, amountCard, setAmountCard, amountInputFocus, setAmountInputFocus ) as any,
                           }}
                />
                
                <NextButton
                    color={'primary'}
                    data-testid="next-btn"
                    type="submit"
                    variant="contained"
                    style={{textTransform:'capitalize'}}
                    fullWidth={true}
                    disabled={!(formik.dirty && formik.isValid)}
                >
                    {
                        "Next"
                    }
                </NextButton>
            </form>
            <CancelPaymentProcessDialog/>
        </Box>)
}

export default PaymentFormStep1