import React from 'react'
import {Grid} from "@mui/material";

// @ts-ignore
const PageGridWrapperTemplate = ({children}) => (
    <Grid container>
        <Grid xs={12} md={2}/>
        <Grid xs={12} md={8}>
            {children}
        </Grid>
        <Grid xs={12} md={2}/>
    </Grid>
)
export default PageGridWrapperTemplate;
