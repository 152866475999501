/* eslint-disable @typescript-eslint/no-unused-expressions */
import { call, put } from 'redux-saga/effects';
import OnTopHttpClient from '../../api/OnTopHttpClient';
import { objectToQuery } from '../../api/HttpClient';
import { ACTIONS } from '../../enums/actions';
import { IDispatchAction } from '../../interfaces/store';
import { HttpResponse } from '../../interfaces/store/api/HttpResponse';
import { GetFriendlyError } from '../../utils/general';
const client = new OnTopHttpClient();


async function login(data: any): Promise<HttpResponse<{ x: string }>> {
  return client.get('ccsLogin' + objectToQuery(data));
}

const onTopSaga = {
  
  *login(action: IDispatchAction): Generator {
    try {
      const response = (yield call(login, action.payload)) as HttpResponse<any>;
      const {data} = response
      const {cLog__LUser} = action.payload as any
      if ((response.status === 200 || response.status === 201) && data) {
        if (data.ccsLogin_response.L_LoginResults == 0) {
          yield put({type: ACTIONS.ERROR, payload: {error: "Wrong Email or Password"}});
          if (action.onFail) {
            action.onFail();
          }
          return
        }
        yield put({type: ACTIONS.LOGIN_SUCCESS, payload: {user: {email: cLog__LUser}}});
        localStorage.setItem('onTopCredentials', JSON.stringify(action.payload))
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
      } else {
        yield put({type: ACTIONS.ERROR, payload: {error: "Something went wrong"}});
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      if (action.onFail) {
        action.onFail();
      }
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({type: ACTIONS.ERROR, payload: {error: friendlyError}});
    }
  },
  
  *logout(action: IDispatchAction): Generator {
    try {
      yield put({
        type: 'LOGOUT_SUCCESS',
        data: {}
      })
      if (action.onSuccess) {
        action.onSuccess();
      }
    } catch (e) {
      if (action.onFail) {
        action.onFail();
      }
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({type: ACTIONS.ERROR, message: friendlyError});
    }
  },

}
export default onTopSaga;
