import {HttpResponse} from "../../interfaces/store/api/HttpResponse";
import {objectToQuery} from "../../api/HttpClient";
import {IDispatchAction} from "../../interfaces/store";
import {call, put} from "redux-saga/effects";
import {ACTIONS} from "../../enums/actions";
import {GetFriendlyError} from "../../utils/general";
import OnTopHttpClient from "../../api/OnTopHttpClient";
const client = new OnTopHttpClient();


async function  makePayment(data:any): Promise<HttpResponse<{ x: string }>> {
    return client.get('ccsTrxnAuthV3'+ objectToQuery(data))
}

const paymentSaga = {
    
    *makePayment(action: IDispatchAction): Generator {
        try {
            const response = (yield call(makePayment, action.payload)) as HttpResponse<any>;
            const { data } = response
            const { ccsTrxnAuthV3_response } = data
            
            if ((response.status === 200 || response.status === 201) && data) {
         
                
                // yield put({ type: ACTIONS.SET_BATCH_HISTORY_SUCCESS, payload: {} });
                
                if (action.onSuccess) {
                    action.onSuccess({
                        status: ccsTrxnAuthV3_response.Authorized === '1',
                        message: ccsTrxnAuthV3_response.ResponseText
                    })
                }
                
            } else {
                yield put({ type: ACTIONS.ERROR, payload: { error: "Something went wrong"} });
                if (action.onFail) {
                    action.onFail();
                }
            }
        }
        catch (e) {
            if (action.onFail) {
                action.onFail();
            }
            const error = e as Error;
            const friendlyError = GetFriendlyError(error.message);
            yield put({ type: ACTIONS.ERROR, payload: { error: friendlyError } });
        }
    },
    
}
export default paymentSaga;