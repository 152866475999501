import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {IBatchHistoryColum} from "../shared/interfaces/transactionHistory.interface";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

interface IHistoryTransactionsTable {
    minWidth?:number;
    data: IBatchHistoryColum[]
}
interface Column {
    id: 'dateAndTime' | 'cardEmail' | 'trxnAmount' | 'cardName' | 'invoiceNumber' | 'cardNumberMask' | 'cardHolderId' | 'AddressVerificationResults' | 'cvvResult' | 'authorizationCode' | 'cardHolderIdCode';
    label: string;
    minWidth?: number;
    maxWidth?: number;
    align: 'right' | 'left' | 'center';
    format?: (value: number) => string;
    responsive?: boolean
}

const columns: readonly Column[] = [
    {
        id: 'dateAndTime',
        label: 'Date & Time',
        minWidth: 120,
        align: 'left',
        responsive: true
    },
    {
        id: 'cardEmail',
        label: 'Card Email',
        minWidth: 150,
        maxWidth: 100,
        align: 'left',
        responsive: true,
        format: (value: number) => value.toFixed(2),
    },
    {
        id: 'trxnAmount',
        label: 'Amount',
        minWidth: 100,
        align: 'center',
        format: (value: number) => new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(value)
    },
    {
        id: 'cardName',
        label: 'Card Name',
        minWidth: 150,
        align: 'left',
        responsive: true,
        format: (value: number) => value.toFixed(2),
    },
    {
        id: 'invoiceNumber',
        label: 'Invoice #',
        minWidth: 100,
        align: 'center',
        format: (value: number) => value.toFixed(2),
    },
    {
        id: 'cardHolderIdCode',
        label: 'Card ID',
        minWidth: 100,
        align: 'center',
    },
    {
        id: 'cardNumberMask',
        label: 'Card Number',
        minWidth: 100,
        align: 'center',
        responsive: true,
        format: (value: number) => new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(value)
    },
    {
        id: 'AddressVerificationResults',
        label: 'AVS',
        minWidth: 70,
        align: 'center',
        format: (value: number) => value.toFixed(2),
    },
    {
        id: 'cvvResult',
        label: 'CVV',
        minWidth: 70,
        align: 'center',
        format: (value: number) => value.toFixed(2),
    },
    {
        id: 'authorizationCode',
        label: 'Auth Code',
        minWidth: 100,
        align: 'center',
        format: (value: number) => value.toFixed(2),
    },
];
function createData(
    dateAndTime: string,
    cardEmail: string,
    trxnAmount: number,
    cardName: string,
    invoiceNumber: string,
    cardNumberMask: string,
    cardHolderId:string,
    AddressVerificationResults: string,
    cvvResult: string,
    authorizationCode: string,
    cardHolderIdCode: string
): IBatchHistoryColum {
    return {
        dateAndTime,
        cardEmail,
        trxnAmount,
        cardName,
        invoiceNumber,
        cardNumberMask,
        cardHolderId,
        AddressVerificationResults,
        cvvResult,
        authorizationCode,
        cardHolderIdCode
    };
}

const formatDataToRow = (data:IBatchHistoryColum[]) => data.map(transaction => createData(
    transaction.dateAndTime,
    transaction.cardEmail,
    transaction.trxnAmount,
    transaction.cardName,
    transaction.invoiceNumber,
    transaction.cardNumberMask,
    transaction.cardHolderId,
    transaction.AddressVerificationResults,
    transaction.cvvResult,
    transaction.authorizationCode,
    transaction.cardHolderIdCode
        ))

const BatchTrxTransactionsTable:React.FC<IHistoryTransactionsTable> = (data:IHistoryTransactionsTable) => {

    const rows = data?.data.length>0 ? formatDataToRow(data.data ) :[]
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(Number(event.target.value));
        setPage(0);
    };
    
    
    return (
        <Paper sx={{ width: '100%', overflow: 'hidden',boxShadow: 0,marginTop:4}}>
            <TableContainer sx={{ maxHeight: 440, borderTopLeftRadius:10, borderTopRightRadius: 10, minWidth:data?.minWidth ? data?.minWidth-500:'auto'}}>
                <Table stickyHeader aria-label="sticky table" >
                    <TableHead  sx={{
                        "& th": {
                            color: "#333333",
                            backgroundColor: "#F7F7F7"
                        }
                    }}>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{width:column.minWidth}}
                                    sx={column?.responsive?{ maxWidth:column.minWidth, width:column.minWidth}:{width:column.minWidth}}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    { rows.length > 0 &&
                        <TableBody sx={{"& td": {color: "#333333"}}}>
                        {rows
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                    {columns.map((column) => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell key={column.id} align={column.align}   sx={!column?.responsive?{minWidth:column.minWidth, maxWidth:column.minWidth}:{width:column.minWidth}}>
                                                {column.format && typeof value === 'number'
                                                    ? column.format(value)
                                                    : value
                                                }
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                    }
                </Table>
                {
                    rows.length === 0 &&
                        <Box sx={{ display: '100%', alignItems: 'center', justifyItems:"center",padding:4,textAlign:'center' }}>
                            <Typography>No Transactions.</Typography>
                        </Box>
                }
            </TableContainer>
            {
                rows.length > 0 && <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    count={rows.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
            }
        </Paper>
    );
}

export default BatchTrxTransactionsTable;
