import {Grid} from "@mui/material";
import React, {useState} from "react";
import PaymentFormStep2 from "./PaymentFormStep2";
import PaymentFormStep1 from "./PaymentFormStep1";

import './payment-page.css'

const PaymentPage = () => {
    const [stepOne, setSteOne] = useState(false)
    
    return(
        <Grid container>
            <Grid item xs={12} md={3} />
            <Grid item xs={12} md={6} >
                    {
                        !stepOne ? <PaymentFormStep1 action={setSteOne}/> : <PaymentFormStep2/>
                    }
            </Grid>
            <Grid item xs={12} md={3} />
        </Grid>
    )
}

export default PaymentPage