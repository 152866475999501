import * as yup from "yup";

export const REGEX_EMAIL=  /@[^.]*\./
export const REGEX_DEBIT_CARD= /^(?:4\d{12}(?:\d{3})?|5[1-5]\d{14}|2(?:2[2-9]\d|[3-7]\d{2}|8[01]\d|820)\d{12}|3[47]\d{13}|6(?:011|5\d{2}|4[4-9]\d)\d{12}|(?:622(?:12[6-9]|1[3-9]\d|[2-8]\d{2}|9[01]\d|92[0-5]))\d{10})$/
export const REGEX_NUMBERS =  /^[0-9]+$/

export const initialValues = {
    invNum : "",
    cardEmail : "",
    amount : "",
    cardName : "",
    cardMonth: "",
    cardYear: "",
    cardNumber : "",
    street : "",
    zip : "",
    cvv : ""
}

export const initialValuesStep1 = {
    invoiceNumber : "",
    amount : ""
}

export const initialValuesStep2 = {
    cardEmail : "",
    cardName : "",
    cardMonth: "",
    cardYear: "",
    cardNumber : "",
    street : "",
    zip : "",
    cvv : ""
}
export const validationSchema = yup.object().shape({
    invNum: yup.string().optional(),
    cardName: yup
    .string()
    .required('Card name is required'),
    cardEmail: yup
    .string()
    .email()
    .matches(REGEX_EMAIL,'Email must be valid. Please insert a correct email')
    .required('Email is required'),
    amount: yup
    .number().typeError('Amount should be higher than 0.99')
    .moreThan(0.99,"Amount should be higher than 0.99")
    .required('Amount is required'),
    cardNumber: yup
    .string()
    .matches(REGEX_DEBIT_CARD)
    .required('Card Number is required'),
    cardMonth: yup
    .string()
    .required('Expired month is required'),
    cardYear: yup
    .string()
    .required('Expired year is required'),
    street: yup
    .string()
    .required('Address is required'),
    zip: yup
    .string()
    .min(5, 'Zipcode must be 5 characters long')
    .max(5, 'Zipcode must be 5 characters long')
    .matches(REGEX_NUMBERS,'Zipcode must be 5 characters long')
    .required('Zipcode is required'),
    cvv: yup
    .string()
    .min(3, 'CVV must has minimum 3 digits')
    .max(3, 'CVV must has maximum 3 digits')
    .matches(REGEX_NUMBERS,'CVV must be numbers')
    .required('CVV is required'),
});


export const validationSchemaStep1 = yup.object().shape({
    invoiceNumber: yup.string().optional(),
    amount: yup
    .number().typeError('Amount should be higher than 0.99')
    .moreThan(0.99,"Amount should be higher than 0.99")
    .required('Amount is required')
});

export const validationSchemaStep2 = yup.object().shape({
    cardName: yup
    .string()
    .required('Card name is required'),
    cardEmail: yup
    .string()
    .email()
    .matches(REGEX_EMAIL,'Email must be valid. Please insert a correct email')
    .required('Email is required'),
    cardNumber: yup
    .string()
    .matches(REGEX_DEBIT_CARD,'Card number must be a valid Visa, Mastercard, American Express, or Discover card')
    .required('Card Number is required'),
    cardMonth: yup
    .string()
    .required('Expired month is required'),
    cardYear: yup
    .string()
    .required('Expired year is required'),
    street: yup
    .string()
    .required('Address is required'),
    zip: yup
    .string()
    .min(5, 'Zipcode must be 5 characters long')
    .max(5, 'Zipcode must be 5 characters long')
    .matches(REGEX_NUMBERS,'Zipcode must be 5 digits long')
    .required('Zipcode is required'),
    cvv: yup
    .string()
    .min(3, 'CVV must has minimum 3 digits')
    .max(3, 'CVV must has maximum 3 digits')
    .matches(REGEX_NUMBERS,'CVV must be numbers')
    .required('CVV is required'),
});
export enum typeMask {
     CREDIT_CARD = 'creditcard',
     CURRENCY = "currency"
}

export const months = [
    {label:"Jan",value:"1"},
    {label:"Feb",value:"2"},
    {label:"Mar",value:"3"},
    {label:"Apr",value:"4"},
    {label:"May",value:"5"},
    {label:"Jun",value:"6"},
    {label:"Jul",value:"7"},
    {label:"Aug",value:"8"},
    {label:"Sept",value:"9"},
    {label:"Oct",value:"10"},
    {label:"Nov",value:"11"},
    {label:"Dec",value:"12"}
]
export const getYears = () => {
    const date = new Date()
    const year = date.getFullYear()
    const totalYears = 10
    const yearList = []
    for(let i=0; i<totalYears; i++){
        yearList.push({label:`${year + i}`, value:`${year + i}`})
    }
    return yearList;
}

export const formFields = [
    {name:"invNum",label:"Invoice Number"},
    {name:"amount",label:"Amount"},
    {name:"cardName",label:"Card holder name"},
    {name:"street",label:"Billing address"},
    {name:"cardEmail",label:"Card Email"},
    {name:"cardNumber",label:"Card Number"}
]