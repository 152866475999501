import * as React from 'react';
import {FC, useState} from "react";

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

interface IItemSelector {
    value: string
    label:string
}
interface IDropdownSelector {
    data: IItemSelector[],
    defaultNumber?: string,
    label?: string,
    action?: Function,
    width?:  number,
    formik?: any,
    name?: string
}
const DropdownList:FC<IDropdownSelector> = ({name, data, defaultNumber="0", label="Selector", action, width ,formik}:IDropdownSelector) => {
    const dropdownWidth = width ? `${width}ch` : 'auto'
    const [requestTypeNum, setRequestTypeNum] = useState<string>('')
    
    const handleSelector = (event:any) => {
        setRequestTypeNum(event.target.value)
        if(action){
            action(event.target.value)
        }
    }
    
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1,marginLeft:0, marginRight:0,flexGrow:1,marginTop:'15px' },
            }}
            style={{width: dropdownWidth }}
            noValidate
            autoComplete="off"
        >
            {
                formik ?
                    <TextField select
                        margin="normal" key={`${name}-inputField`}
                        inputProps={{'data-testid': `${name}-inputField`, style: { textTransform: "uppercase" }}}
                        fullWidth
                        id={name}
                        name={name}
                        // @ts-ignore
                        value={formik.values[name]}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        // @ts-ignore
                        error={formik.touched[name] && Boolean(formik.errors[name])}
                        // @ts-ignore
                        helperText={formik.touched[name] && formik.errors[name]}
                    >
                        {data.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>:
                    <TextField fullWidth
                        id="outlined-select-option"
                        select
                        label={label}
                        defaultValue={defaultNumber}
                        value={!requestTypeNum ? defaultNumber: requestTypeNum}
                        onChange={handleSelector}
                    >
                        {data.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
            }
        </Box>
    );
}

export default DropdownList;