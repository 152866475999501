export enum directionTypes {
    ROW='row',
    COL='column'
}
export interface IFilterPanel{
    direction: directionTypes
    searchInput: string
    handleSearch: (val:any) => void
    updateDataParentRender: Function
    dropdownWidth?: number
    propStyles?: any
}