export enum APP_BREAKPOINT {
  EXTRA_SMALL,
  SMALL,
  MEDIUM,
  LARGE,
  EXTRA_LARGE,
}

export enum COLORS {
  WHITE_BACKGROUND = "white",
  RED_BRAND_PRIMARY = "#D8010E",
  BRAND_PRIMARY = "#DC143C",
  BRAND_PRIMARY_OPACITY20 = "#DC143C33",
  BRAND_PRIMARY_DARK = "#D20013",
  GREY_DARK = "#414042",
  GREY_DARK2 = "#333333",
  MEDIUM_GREY = "#5B5B5B",
  MEDIUM_RED = "#BF737F",
  LIGHT_RED = "rgba(216, 1, 14, 0.1)",
  DARK_RUSTY_RED = "#A8455A",
  MODAL_BACKGROUND = "rgba(248, 250, 251, 0.6)",
  SCREEN_BACKGROUND = "#FAFAFA",
}

export enum THEME_NAME {
  LIGHT = "light",
  DARK = "dark",
}

export interface ITheme {
  colors: IColorsTheme;
  classes: {
    main: string;
  };
}

export interface IColorsTheme {
  background: string;
  font: string;
  accent_primary: string;
  icon_primary: string;
  sidebar_background: string;
}

export interface IClassesTheme {
  main: string;
}

export const getThemeColors = (theme: THEME_NAME): IColorsTheme => {
  let colorsDefs = {
    background: "white",
    font: "black",
    accent_primary: "#DC143C",
    icon_primary: "#D20013",
    sidebar_background: "white",
  };
  switch (theme) {
    case THEME_NAME.LIGHT: //default
      break;
    case THEME_NAME.DARK:
      colorsDefs = {
        background: "black",
        font: "white",
        accent_primary: "#D20013",
        icon_primary: "#D20013",
        sidebar_background: "#414042",
      };
      break;
  }
  return colorsDefs;
};

export const getThemeClasses = (theme: THEME_NAME): IClassesTheme => {
  let classesDef = {
    main: "theme-light",
  };
  switch (theme) {
    case THEME_NAME.LIGHT: //default
      break;
    case THEME_NAME.DARK:
      classesDef = {
        main: "theme-dark",
      };
      break;
  }
  return classesDef;
};

export const MARGIN_SMALL = 8;
export const MARGIN_MEDIUM = 16;
export const MARGIN_LARGE = 32;

export const errorStyle = {
  color: "red",
};

export const TABLE_DEFAULT_PAGE_SIZE = 15;
export const TABLE_DEFAULT_PAGE_SIZES = [5, 15, 30, 50, 100];

export const maxWidthMobileScreen = 450;
