import React from "react"
import Avatar from "@mui/material/Avatar";
import {COLORS} from "../../shared/constants/layout-constants";
import Typography from "@mui/material/Typography";
import {Stack} from "@mui/material";

interface IUserAvatar {
    username: string,
    email: string,
    middleName: string
}

const UserAvatar = ({middleName,username,email}:IUserAvatar) => {

    
    return <Stack direction={"row"}  style={{borderBottom:'1px solid #e8e8e8', paddingBottom:'10px',marginBottom:'10px',minWidth:'230px'}}>
                <Avatar variant="rounded" style={{width:50,height:50,borderRadius:"13px",backgroundColor:COLORS.LIGHT_RED}} alt={middleName} src="/static/images/avatar/2.jpg">
                    <Typography data-testid="avatar-middlename" fontSize={24} fontWeight={500} color={COLORS.RED_BRAND_PRIMARY}>{middleName}</Typography>
                </Avatar>
                <Stack direction="column" spacing={0} marginLeft={1} paddingBottom={1}>
                    <Typography data-testid="avatar-username" color={COLORS.GREY_DARK2} fontSize={18} fontWeight={800}>{ username }</Typography>
                    <Typography data-testid="avatar-email" color={COLORS.GREY_DARK2}  fontSize={16} fontWeight={500}>{ email }</Typography>
                </Stack>
           </Stack>
}

export default UserAvatar;