import React, {FC} from "react";
import { List, AutoSizer } from "react-virtualized";

import {IBatchHistoryColum} from "../../../shared/interfaces/transactionHistory.interface";
import TransactionCard from "./TransactionCard";
import {Grid} from "@mui/material";


interface ITransactionList2{
    list: IBatchHistoryColum[],
    width: number
    height: number
}
const TransactionList:FC<ITransactionList2> = ({ list, width, height }:ITransactionList2) => {
    const totalList = list?.length ?? 0
    const rowHeight = 140;
    
    if(totalList<1){
        return <></>
    }
    
    return(
        <Grid container>
            <Grid xs={0}/>
            <Grid xs={12}>
                <AutoSizer disableHeight={true}>
                    {() => <List
                        style={{flexWrap:"wrap"}}
                        width={width}
                        height={height-100}
                        rowCount={totalList*rowHeight}
                        rowHeight={rowHeight}
                        rowRenderer={({ index }) => list[index] && <TransactionCard index={index} transaction={list[index]}/>}
                    />}
                </AutoSizer>
            </Grid>
            <Grid xs={0}/>
        </Grid>
)
};

export default TransactionList;