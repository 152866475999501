import * as yup from "yup";
import {REGEX_EMAIL} from "../../private/PaymentPage/constant";


export const validationSchema = yup.object({
    email: yup
    .string()//'Enter your email'
    .email('Enter a valid email')
    .matches(REGEX_EMAIL,'Email must be valid. Please insert a correct email')
    .required('Email is required'),
    password: yup
    .string()//'Enter your password'
    .min(5, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
});