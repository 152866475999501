import {Grid} from "@mui/material";
import { ReactComponent as SplashLogo} from '../../assets/svg/splashLogo.svg';
const SplashScreen = () => {
    
    return(
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{
                height: "100vh",
                backgroundColor:'#FFF',
                padding:0
            }}
        >
            <SplashLogo/>
        </Grid>
    )
}

export default SplashScreen