import {FC} from "react";
import {IBatchHistoryColum} from "../../../shared/interfaces/transactionHistory.interface";
import {Card, CardContent, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import useScreenSize from "../../../hooks/useScreenSize";
import {getDateUTCFormat} from "../../../utils/general";
import Box from "@mui/material/Box";


interface ITransactionCard{
    index?:number
    transaction: IBatchHistoryColum
}

const currencyFormatter = (amount:number | string) => {
    const amountNumber = parseFloat(String(amount))
    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    return USDollar.format(amountNumber)
}
const getCardWith = (val:number) => {
    let finalWidth = '77%'
    
    if(val < 1100){
        finalWidth = '75%'
    }
    if (val < 500){
        finalWidth = '86.5%'
    }
    
    return finalWidth
}
const TransactionCard:FC<ITransactionCard> = ({ index, transaction }:ITransactionCard) => {
    //const cardPosition = index ? index + 1: 0
    const {width} = useScreenSize()
    const cardWidth = getCardWith(width)
    
    const fontSize = width < 500 ? "13px" : "16px"
    const fontSizeAmount = fontSize === "13px" ? "20px": "25px"
    const paddingCard = width < 500 ? 0 : 3
    const spacingRow = width < 500 ? 2 : 0
    const justifyContentRow = width < 500 ? "none" : "space-between"
    
    return(
        <Card sx={{marginBottom:3,padding:paddingCard,height:'auto',width:cardWidth, boxShadow:'2px 2px 2.5px 2.5px #E8E8E8'}}>
            <CardContent>
                {/*<Typography variant="caption">index[{cardPosition}]</Typography>*/}
                <Stack direction="row" alignItems={'flex-start'} sx={{marginBottom:3.5}} >
                    <Stack direction="row" width={'75%'}
                           spacing={spacingRow}
                           justifyContent={justifyContentRow}
                           alignItems="top">
                        <Typography sx={{fontSize:fontSize,fontWeight:'lighter'}} variant="body1">
                            <Box sx={{}}> {getDateUTCFormat(transaction.dateAndTime).date}</Box>
                            <Box sx={{}}> {getDateUTCFormat(transaction.dateAndTime).time}</Box>
                        </Typography>
                        <Typography sx={{fontSize:fontSize, marginRight:'15%'}} variant="body1">{transaction.cardEmail}</Typography>
                    </Stack>
                    <Stack direction="row" style={{ width:'25%'}} justifyContent={"flex-end"}>
                        <Typography sx={{fontSize:fontSizeAmount}} variant="h5" fontWeight={400}>{currencyFormatter(transaction.trxnAmount)}</Typography>
                    </Stack>
                </Stack>
                
                <Stack direction="row"
                       marginTop={2}
                       spacing={spacingRow}
                       justifyContent={"space-between"}
                       alignItems="center" sx={{marginBottom:4}}>
                    <Typography sx={{fontSize:fontSize,transformText:'uppercase'}} variant="body1" color="#D8010E">{transaction.cardName}</Typography>
                    <Typography sx={{fontSize:fontSize,transformText:'uppercase'}} variant="body1" color="#D8010E">{transaction.invoiceNumber}</Typography>
                </Stack>
                <Stack direction="row"
                       marginTop={2}
                       spacing={spacingRow}
                       justifyContent={"space-between"}
                       alignItems="center">
                    <Typography sx={{fontSize:fontSize}} variant="body1">{transaction.cardNumberMask}</Typography>
                    <Typography sx={{fontSize:fontSize,transformText:'uppercase'}} variant="body1">{transaction.cardHolderId}</Typography>
                    <Typography sx={{fontSize:fontSize,transformText:'uppercase'}} variant="body1">{transaction.AddressVerificationResults}</Typography>
                    <Typography sx={{fontSize:fontSize,transformText:'uppercase'}} variant="body1">{transaction.cvvResult}</Typography>
                    <Typography sx={{fontSize:fontSize,transformText:'uppercase'}} variant="body1">{transaction.authorizationCode}</Typography>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default TransactionCard