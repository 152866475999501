import {HttpResponse} from "../../interfaces/store/api/HttpResponse";
import {objectToQuery} from "../../api/HttpClient";
import {IBatchHistoryColum, ITransactionHistory} from "../../shared/interfaces/transactionHistory.interface";
import {IDispatchAction} from "../../interfaces/store";
import {call, put} from "redux-saga/effects";
import {ACTIONS} from "../../enums/actions";
import {GetFriendlyError} from "../../utils/general";
import OnTopHttpClient from "../../api/OnTopHttpClient";
const client = new OnTopHttpClient();


async function  getBatchHistory(data:any): Promise<HttpResponse<{ x: string }>> {
    return client.get('ccsTrxnHist'+ objectToQuery(data))
}

const formatDateAndTime = (entryDate:number, entryTime:number) => {
    const stringDate = String(entryDate)
    const stringTime = String(entryTime)
    
    const year = Number(stringDate.substring(0,4))
    const month = Number(stringDate.substring(4,6))
    const day = Number(stringDate.substring(6,8))
    
    const hh = Number(stringTime.substring(0,2))
    const mm = Number(stringTime.substring(2,4))
    
    return  `${month}/${day}/${year} ${hh}:${mm}`
}

const formatDataToBatchHistoryTable = (data: ITransactionHistory[]) => {
    return data.map((trxn:ITransactionHistory) => {
        return(
            {
                dateAndTime: formatDateAndTime(trxn.CTRXH_TDATE, trxn.CTRXH_TTIME),
                cardEmail: trxn.CTRXH_CARDEMAIL,
                trxnAmount: Number(trxn.CTRXH_AMOUNT),
                cardName: trxn.CTRXH_CARDNAME,
                invoiceNumber: trxn.CTRXH_INV_NUM,
                cardNumberMask: trxn.CTRXH_MASKEDCARDNUMBER,
                cardHolderId: trxn.CTRXH_CARDHOLDERIDCODE,
                AddressVerificationResults: trxn.CTRXH_AVSRESULTCODE,
                cvvResult: trxn.CTRXH_CVV2RESULT,
                authorizationCode: trxn.CTRXH_APPROVALCODE,
                cardHolderIdCode: trxn.CTRXH_CARDHOLDERIDCODE
            }
        )
    })
}

const getDataAsList = (data: any) =>  {
    const convertToList = (data:any) => typeof data !== 'object' ? [] :[data]
    return Array.isArray(data) ? data : convertToList(data)
}

const transactionSaga = {
    
    *getBatchHistory(action: IDispatchAction): Generator {
        try {
            const response = (yield call(getBatchHistory, action.payload)) as HttpResponse<any>;
            const { data } = response
            const { ccsTrxnHist_response } = data
            
            if ((response.status === 200 || response.status === 201) && data) {
                const formatDataToList: ITransactionHistory[] = getDataAsList(ccsTrxnHist_response?.ccsTrxnHist?.Trxn_History)
                const transactions:IBatchHistoryColum[]  = formatDataToList.length > 0 ? formatDataToBatchHistoryTable(formatDataToList) : []
                
                yield put({ type: ACTIONS.SET_BATCH_HISTORY_SUCCESS, payload: transactions });
                
                if (action.onSuccess) {
                    action.onSuccess({ transactions})
                }
                
            } else {
                yield put({ type: ACTIONS.ERROR, payload: { error: "Something went wrong"} });
                if (action.onFail) {
                    action.onFail();
                }
            }
        }
        catch (e) {
            if (action.onFail) {
                action.onFail();
            }
            const error = e as Error;
            const friendlyError = GetFriendlyError(error.message);
            yield put({ type: ACTIONS.ERROR, payload: { error: friendlyError } });
        }
    },
    
}
export default transactionSaga;