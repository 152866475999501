import {InputAdornment, OutlinedInput, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import DropdownList from "../../../components/general/DropdownList";
import React, {FC} from "react";
import { IFilterPanel } from "./types";
import {setBatchHistoryRequestTypeNum} from "../../../store/actions/batchActions";
import {useDispatch} from "react-redux";
import SearchIcon from "@mui/icons-material/Search";


const requestTypeOptions = [{value:"0",label:"Current Batch"},{value:"1",label:"Last Batch"},{value:"2",label:"All Data"}]
const FilterPanel:FC<IFilterPanel> = ({searchInput,handleSearch,updateDataParentRender, direction, dropdownWidth, propStyles={}}:IFilterPanel) => {
    const dispatch = useDispatch();
    const itemsAlignment = direction==='row' ? 'center': 'left'
    const searchInputWidth = direction==='row' ? 300 : '100%'
    
    const handleDropdownAction = (val:string) => {
        dispatch(setBatchHistoryRequestTypeNum(val))
        updateDataParentRender()
    }
    
    return(
        <Stack sx={propStyles}
            direction={direction}
            alignItems={itemsAlignment}
            justifyContent="space-between" >
            <Typography variant="h6" fontWeight={600}>TRANSACTION HISTORY</Typography>
            <br/>
                <OutlinedInput
                    size="small"
                    value={searchInput}
                    onChange={handleSearch}
                    placeholder="Search by Auth Code"
                    style={{border:'.6px solid #C4C4C4'}}
                    name="search-input"
                    sx={{width:searchInputWidth,padding:1.2, borderRadius:'5px'}}
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon sx={{width:24,color:'#60737D',marginTop:-.2}} />
                        </InputAdornment>
                    }
                />
            {/*Values for ReqType:  0 = Current Batch, 1 = Last Batch, 2 = All Data */}
            <DropdownList
                label="Filter by:"
                defaultNumber="2"
                data={requestTypeOptions}
                action={handleDropdownAction}
                width={dropdownWidth}
            />
        </Stack>
    )
}
export default FilterPanel