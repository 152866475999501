import {
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import React  from "react";
import {login} from "../../../store/actions/authActions";
import {useDispatch} from "react-redux";
import LoginIcon from '../../../assets/login/login_icon.svg'
import {useNavigate} from "react-router";
import {useFormik} from "formik";
import {validationSchema} from "./loginValidationSchema";
import FormHelperText from "@mui/material/FormHelperText";
import {LoginButton} from "./LoginButton";


interface IPhoneLoginProps {}

const DesktopLoginForm: React.FC<IPhoneLoginProps> = (props) => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = React.useState(false);
    
    const handleForgotPassword = () => {
        navigate(`/ForgotPassword`);
    };
    
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema,
        onSubmit: (values) => {
            dispatch(
                login(
                    { cLog__LUser: values.email, cLog__LPassword: values.password },
                    () => {
                        navigate('/Dashboard');
                    },
                    () =>{},
                    "Login successful.", true, true
                )
            );
        },
    });
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    
    return (
        <>
            <img src={LoginIcon} alt="" style={{
                marginRight: 80
            }}/>
            <Card style={{ padding: 30, borderRadius: 16, width: 450}}>
                <CardContent>
                    <Paper elevation={0} style={{
                        marginBottom: 10
                    }}>
                        <Typography variant="h5">Hello! Log in with your PAYLEX data.</Typography>
                    </Paper>
                    <form autoComplete="off" onSubmit={formik.handleSubmit}>
                            <TextField
                                name="email"
                                label="E-mail"
                                size="small"
                                fullWidth={true}
                                variant="standard"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        <FormControl style={{ marginTop: 16, width: "100%"}} variant="standard">
                            <InputLabel htmlFor="password">Password</InputLabel>
                            <Input
                                size="small"
                                fullWidth={true}
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <VisibilityOff color="primary" /> : <Visibility color="primary" />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText style={{color:'#D8010E',marginTop:-2}}>{formik.touched.password && formik.errors.password}</FormHelperText>
                        </FormControl>
                        
                        <FormControlLabel control={<Checkbox />} label="Remember Me" />
                        <div style={{ marginTop: 16, display: "flex", justifyContent: "center"}}>
                            <Button onClick={() => handleForgotPassword()} size="medium" style={{textTransform: 'none'}}>
                                Forgot your password?
                            </Button>
                        </div>
                        <div style={{ marginTop: 36 }}>
                            <LoginButton disabled={!(formik.dirty && formik.isValid)} color="primary" variant="contained" fullWidth type="submit">
                                Log in
                            </LoginButton>
                        </div>
                    </form>
                </CardContent>
            </Card>
        </>
    );
};

export default DesktopLoginForm;