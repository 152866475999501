import React from "react"
import { Divider, Grid, Typography} from "@mui/material";
import {COLORS} from "../../../shared/constants/layout-constants";
import {Link} from "react-router-dom";
import {getDateUTCFormat} from "../../../utils/general";

export interface ITransactionCardProps {
  dateAndTime: string
  cardName: string
  trxnAmount: number
  redeemDate?: string
  isMobile?:boolean
}

const TransactionRow: React.FC<ITransactionCardProps> = (props) => {
  const {isMobile, dateAndTime, cardName,trxnAmount } = props
  const dateStyle = isMobile ? {color:'#5B5B5B', fontSize:10}: {color:'#5B5B5B'}
  const titleStyle = isMobile ? {fontSize:12}: {fontSize:16}
  
  return (
    <Grid container item style={{ maxWidth:'1200px'}}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid container item flexDirection="column" xs={6} justifyContent="flex-start">
          <Typography variant="caption" style={dateStyle}>{getDateUTCFormat(dateAndTime).date}</Typography>
          <Typography variant="h6" style={titleStyle}>{cardName}</Typography>
          {/* TODO.- Investigate if we are going to use this redeem value*/}
          {/*<Typography variant="caption">To redeem {props.redeemDate}</Typography>*/}
        </Grid>
        <Grid container item flexDirection="column" xs={6} justifyContent="flex-end" alignItems="flex-end">
          <Typography variant="h6" style={{fontSize:16}}>${trxnAmount.toFixed(2)}</Typography>
          <Link to="/dashboard" style={{fontSize:isMobile?12:16,fontFamily:'NotoSans-Regular',fontWeight:'bold',color:COLORS.BRAND_PRIMARY, cursor:'pointer'}}>Invoice</Link>
        </Grid>
      </Grid>
      <Divider style={{ width: "100%",marginTop:2}} />
    </Grid>
  )
}

export default TransactionRow
