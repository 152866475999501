
import React, {useEffect, useState} from 'react';
import {getBatchHistory} from "../../../store/actions/batchActions";
import {useDispatch, useSelector} from "react-redux";
import { useNavigate} from "react-router-dom";
import {Button, Card, CircularProgress} from "@mui/material";
import Box from "@mui/material/Box";

import BatchTrxTransactionsTable from "../../../components/BatchTrxTransactionsTable"
import {IBatchHistoryColum} from "../../../shared/interfaces/transactionHistory.interface";
import {LOCALSTORAGE_VALUES, PROTECTED_ROUTES} from "../../../shared/constants/app-constants";
import {getLocalstorageValue} from "../../../utils/localstorage";
import useScreenSize from "../../../hooks/useScreenSize";
import TransactionList from "../../../components/general/TransactionList/TransactionList";
import FilterPanel from "./FilterPanel";
import {directionTypes} from "./types";

const MINIMUM_CARD_WIDTH = 1520;
const filterByValue = (list:any[], val:string) => list.filter(trx => trx.authorizationCode.toLowerCase().includes(val.toLowerCase()))

const sortList = (list:any[], sortBy:string='Desc') => {
    return list.sort((a, b) => {
        // @ts-ignore
        return sortBy ==="Desc" ? new Date(b.dateAndTime) - new Date(a.dateAndTime):new Date(a.dateAndTime) - new Date(b.dateAndTime);
    })
}
const BatchHistoryPage = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const screenSize = useScreenSize();
    
    const { batchHistoryList, batchHistoryRequestTypeNum, my_user  } = useSelector((state: any) => state?.main)
    const [transactions, setTranssactions] =useState<IBatchHistoryColum[]>([])
    const [loading, setLoading] = useState<boolean>(true);
    const [searchInput, setSearchInput] = useState<string>("");
    const [horizontalPadding, setHorizontalPadding] = useState<number>(10)

    const updateDataRender = (val:string) => {
        setTranssactions([])
    }

    const handleBatchHistoryRequest = (email:string, requestType:string) => {
        const { cLog__LPassword } = getLocalstorageValue(LOCALSTORAGE_VALUES.CREDENTIALS)
  
        dispatch(getBatchHistory({ cTrxH__User: email, cTrxH__Password:cLog__LPassword, cTrxH__ReqType: batchHistoryRequestTypeNum },
            (transactions) => {
                setTranssactions(transactions)
                setLoading(false)
            },
            () =>{},
            "Request successful.", true, true
        ))
    }
    
    const handleSearch = (event:any) => {
        setSearchInput(event.target.value)
    }

    useEffect(() => {
        handleBatchHistoryRequest(my_user.email, batchHistoryRequestTypeNum)
    }, []);

    useEffect(() => {
        handleBatchHistoryRequest(my_user.email, batchHistoryRequestTypeNum)
    }, [batchHistoryRequestTypeNum]);

    useEffect(() => {
        const listTrxnSorted = sortList(batchHistoryList)
        setTranssactions(searchInput.length ? filterByValue(listTrxnSorted,searchInput): listTrxnSorted)
    }, [transactions, searchInput]);
    
    useEffect(() => {
        setTimeout(()=> {
            setHorizontalPadding(screenSize.width < 500 ? 0.5 : 10)
        },3000)
    }, [screenSize.width]);

    return (
        <div style={{height:'100vh',overflow:'hidden', paddingLeft:`${horizontalPadding}%`, paddingRight:`${horizontalPadding}%`}}>
            <Button variant="text" sx={{color:'#D8010E',marginBottom:4}} onClick={() => navigate(PROTECTED_ROUTES.DASHBOARD)} >BACK</Button>
            
            {
                !loading && screenSize.width > 1950 &&
                <Card  sx={{
                    paddingTop:1,
                    paddingBottom:4,
                    paddingLeft:4,
                    paddingRight:4,
                    borderRadius:6,
                    minWidth:MINIMUM_CARD_WIDTH
                }} >
                    <FilterPanel direction={directionTypes.ROW} dropdownWidth={20} searchInput={searchInput} handleSearch={handleSearch} updateDataParentRender={updateDataRender}/>
                    {
                        loading ?
                            (<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyItems:"center" }} alignContent="center">
                                <CircularProgress color="primary"  />
                            </Box>) : <BatchTrxTransactionsTable data={transactions} minWidth={MINIMUM_CARD_WIDTH} />
                    }
                </Card>
            }
            
            {
                !loading && screenSize.width < 1950 &&
                <>
                    <FilterPanel direction={directionTypes.COL}  searchInput={searchInput} handleSearch={handleSearch} updateDataParentRender={updateDataRender} propStyles={{marginBottom:5}}/>
                    <TransactionList list={transactions} width={screenSize.width} height={screenSize.height}/>
                </>
            }

        </div>
    )
};

export default BatchHistoryPage;
