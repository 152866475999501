import React from "react"
import {Card, CardActionArea, CardContent, Grid, Typography} from "@mui/material";
import { Link } from "react-router-dom";
import useScreenSize from "../../../hooks/useScreenSize";

export interface IDashboardCardProps {
  icon: any
  text: string
  route?: string
}

const DashboardCard: React.FC<IDashboardCardProps> = (props) => {
  const {width:screenWidth}= useScreenSize()
  const mobileCardSize = (screenWidth/3)-30
  
  const styleDesktopCard = {width:`120px`,height:'140px',borderRadius:'16px', marginTop:'20px',marginRight:'2px'}
  const styleMobileCard = {width:`${mobileCardSize}px`,height:`${mobileCardSize +20}px`,borderRadius:'16px', marginTop:'20px',marginRight:'2px'}
  
  const styleCard = screenWidth<450 ? styleMobileCard : styleDesktopCard
  const iconSize = 45
  const fontSize = screenWidth<450 ? 12 : 12
  const labelAsList =props.text.split(' ')
  
  return (
      <Card style={styleCard} sx={{boxShadow:'2px 2px 2.5px 2.5px #E8E8E8'}}>
        <Link to={props.route || ""} style={{ textDecoration: 'none', color: "black" }}>
          <CardActionArea style={{height:'100%'}}>
            <CardContent style={{alignContent:'center',textAlign:'center'}}>
              <Grid container justifyContent="center" alignItems="center" flexDirection="column">
                <img style={{ height: iconSize, width: iconSize }} src={props.icon} alt=""/>
                <Typography variant="body1" fontSize={fontSize} fontWeight={'lighter'} style={{ marginTop: 10, color:'#333333' }}>{labelAsList[0]}<br/>{labelAsList[1]}</Typography>
              </Grid>
            </CardContent>
          </CardActionArea>
        </Link>
      </Card>
  )
}

export default DashboardCard
