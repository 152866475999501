import { ACTIONS } from "../../enums/actions";
import { genericAction } from "./genericAction";

export const makePayment = genericAction(ACTIONS.MAKE_PAYMENT);

export const paymentStepOne = (payload: any, onSuccess: Function | null = null, onFail: Function | null = null) => ({
    type: ACTIONS.PAYMENT_STEP1,
    payload,
    onSuccess: onSuccess || null,
    onFail: onFail || null
});