import React from "react"
import AppBar from "@mui/material/AppBar";
import {COLORS} from "../../../shared/constants/layout-constants";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import PaylexLogo from "../../../assets/svg/paylex-logo.svg";
import Box from "@mui/material/Box";

import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {logout} from "../../../store/actions/authActions";
import UserAvatar from "../UserAvatar";
import {validateEmail} from "../../../utils/validator";
import Popper from "@mui/material/Popper";

const getUsername = (email:string) => validateEmail(email) ? email.split('@')[0] : '--'
const getMiddleName = (email: string) => email.charAt(0).concat(email.charAt(1)).toUpperCase()

const getMenuItems = (navigate: any, dispatch: Function) => {
    return [
        {label:'Settings', action:()=>{}},
        {label:'Change Password', action:()=>{}},
        {label:'Log Out' ,action:() => dispatch(logout({}, () => {
                navigate('/Admin')
            }))}];
}

interface INavBarMenu{
    isPublic?: boolean
}

const NavBarMenu:React.FC<INavBarMenu> = ({isPublic = false}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {email: userEmail} = useSelector((state: any) => state?.main?.my_user)
    
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    
    return <AppBar
                style={{ backgroundColor: COLORS.WHITE_BACKGROUND}}
                position="fixed"
                elevation={0}
            >
                <Toolbar>
                    <div style={{ display: 'flex', width: '100%'}}>
                        <div style={{ flex: 1 }}>
                            <img src={PaylexLogo} onClick={() => {
                                navigate('/Dashboard')
                            }} alt={'Paylex logo'} width={80} style={{ cursor: 'pointer', float: 'left', marginTop:'5px' }}/>
                        </div>
                        
                        {
                            !isPublic &&  <Box sx={{ flexGrow: 0 }}>
                                <IconButton data-testid="navbar-icon-menu" aria-describedby={id} onClick={handleClick} sx={{ p: 0 }}>
                                    <Avatar variant="rounded" sizes={"32"}  style={{borderRadius:"12px",backgroundColor:COLORS.LIGHT_RED}} >
                                        <Typography fontSize={18} color={COLORS.RED_BRAND_PRIMARY}>{getMiddleName(userEmail)}</Typography></Avatar>
                                </IconButton>
                                <Popper
                                    id={id}
                                    open={open}
                                    keepMounted
                                    anchorEl={anchorEl}
                                    disablePortal
                                    placement="bottom-end"
                                    style={{marginTop:"100px", backgroundColor:"transparent",paddingTop:4,paddingLeft:50}}
                                >
                                    <Box
                                        sx={{
                                            boxShadow: 4,
                                            position: "relative",
                                            mt: "10px",
                                            "&::before": {
                                                backgroundColor: COLORS.WHITE_BACKGROUND,
                                                content: '""',
                                                display: "block",
                                                position: "absolute",
                                                width: 12,
                                                height: 12,
                                                top: -6,
                                                transform: "rotate(45deg)",
                                                left: "calc(92% - 3px)"
                                            }
                                        }}
                                    />
                                    <Box data-testid="navbar-popup-container"  sx={{ boxShadow: 1 }} style={{paddingLeft:40,paddingRight:40,paddingTop:20,paddingBottom:20,backgroundColor:COLORS.WHITE_BACKGROUND,borderRadius:14,marginRight:"-15px"}}>
                                        <UserAvatar email={userEmail} username={getUsername(userEmail)} middleName={getMiddleName(userEmail)}/>
                                        {getMenuItems(navigate,dispatch).map((item) => (
                                            <MenuItem  key={item.label} onClick={handleClick} disableGutters={true}>
                                                <Typography fontSize={16} textAlign="center" onClick={item.action} color={COLORS.MEDIUM_GREY}>{item.label}</Typography>
                                            </MenuItem>
                                        ))}
                                    </Box>
                                
                                </Popper>
                            </Box>
                        }
                       
                    </div>
        
                </Toolbar>
            </AppBar>
}
export default NavBarMenu