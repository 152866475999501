
import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Login from './pages/public/auth/LoginPage';
import ForgotPassword from './pages/public/auth/ForgotPasswordPage';
import ResetPassword from './pages/public/auth/ResetPasswordPage';
import ResetPasswordNextStep from './pages/public/auth/ResetPasswordNextStepPage';
import {Navigate, Route, Routes} from 'react-router-dom';
import Privacy from './pages/public/privacy/PrivacyPage';
import Terms from './pages/public/terms/Terms';
import VerifyEmail from './pages/private/site/VerifyEmail';

import { useSelector } from './store';
import { useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { getGlobalSettings } from './store/actions/globalSettingsActions';
import { IRootState } from './interfaces/store';
import {COLORS} from "./shared/constants/layout-constants";

const authContStyle = {
	marginTop: 16, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'
}

function AppPublic(props: any) {

	const dispatch = useDispatch();
	const theme = useSelector((state: IRootState) => state.main.theme);

	useEffect(() => {
			dispatch(getGlobalSettings({}, () => {}))
	}, [])

	return (
		<div style={{width: '100vw', height: '100vh', backgroundColor: COLORS.SCREEN_BACKGROUND, color: theme.colors.font}}>
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />
			<Box component="main" sx={{ width: '100%' }}>
				<Routes>
					<Route path="/" element={<Login />} />
					<Route path="ResetPasswordNextStep" element={<div style={authContStyle}>
						<ResetPasswordNextStep />
					</div>} />
					<Route path="Admin" element={<div style={authContStyle}>
						<Login />
					</div>} />
					<Route path="ForgotPassword" element={<div style={authContStyle}>
						<ForgotPassword />
					</div>} />
					<Route path="ResetPassword" element={<div style={authContStyle}>
						<ResetPassword />
					</div>} />
					<Route path="VerifyEmail" element={<div style={authContStyle}>
						<VerifyEmail />
					</div>} />
					<Route path="TestPhone" element={
						<div>
							<div onClick={() => window.open("ontop://open", "_blank")} style={{ cursor: 'pointer', width: '100%', textAlign: 'center' }}>
								LAUNCH APP 1
							</div>
							<br /><br />
							<a href="ontop://open">LAUNCH APP 2</a>
						</div>} />
					<Route path="Privacy" element={<Privacy />} />
					<Route path="Terms" element={<Terms />} />
					<Route path="*" element={<Navigate to='/admin' replace />} />
				</Routes>
			</Box>
		</Box>
		</div>

	)

}

export default AppPublic
