import React from 'react'
import Box from "@mui/material/Box";
import {Button} from "@mui/material";
import { useNavigate } from "react-router-dom";


export const NotFoundPage = () => {
    let navigate = useNavigate()

    return(
        <Box style={{width:'100%',textAlign:'center',verticalAlign:'center',marginTop:200}}>
            <h2>Not Found Page!</h2>
            <Button variant='outlined'  onClick={() => navigate('/dashboard')}>Dashboard</Button>
        </Box>
    )
}