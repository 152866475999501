import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import {IResponseMessage} from "../../../pages/private/PaymentPage/interfaces";
import {useEffect} from "react";
import {Paper, Stack, Typography} from "@mui/material";
import InfoIcon from "../../../assets/svg/ExclamationCircle.svg";
import useScreenSize from "../../../hooks/useScreenSize";
import {maxWidthMobileScreen} from "../../../shared/constants/layout-constants";


interface IDialogPopUp {
    screenType?:string,
    display: boolean,
    info?: IResponseMessage,
    message?: string,
    action?: Function,
    action2?: Function,
}
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogPopUp:React.FC<IDialogPopUp> = ({screenType='Desktop', display, message, action, action2=()=>{}}:IDialogPopUp) => {
    
    const {width} = useScreenSize();
    const isMobile = width < maxWidthMobileScreen
    const [open, setOpen] = React.useState<boolean>(false);
    const dialogPadding = screenType==='Desktop'? 10 : 0;
    
    const handleClose = () => {
        // @ts-ignore
        action(false)
        setOpen(false)
    };
    
    useEffect(() => {
        setOpen(display)
    }, [display]);
    
    return (
        <React.Fragment>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                sx={{padding: dialogPadding}}
            >
                <Paper sx={isMobile?{}:{padding: 3}}>
                <DialogTitle>
                    <Stack direction="row">
                        <img src={InfoIcon} alt={'information'} width={30} style={{ cursor: 'pointer', float: 'left', marginRight:'10px' }}/>
                        <Typography style={{fontSize:"18px"}}>
                            Failure Payment Request
                        </Typography>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" style={{fontSize:"16px"}}>
                        {`There is an issue due ${message}.`}
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-slide-description" style={{fontSize:"16px"}}>
                        {`Please check the information inserted.`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" style={{color:"#fff", backgroundColor:"#D8010E", textTransform:"capitalize"}} onClick={handleClose}>Got it!</Button>
                </DialogActions>
                </Paper>
            </Dialog>
        </React.Fragment>
    );
}

export default DialogPopUp