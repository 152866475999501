import React from "react";
import {Button, Typography} from "@mui/material";
import {COLORS} from "../../../shared/constants/layout-constants";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";

import SuccessPaymentLogo from "../../../assets/svg/completing-payment.svg"
import {PROTECTED_ROUTES} from "../../../shared/constants/app-constants";
import {formatAmount} from "../../../utils/helpers";

interface IResponseTransactionComponent{
    status: boolean,
    transactionNumber: string,
    amount: number
}
const ResponseTransactionComponent:React.FC<IResponseTransactionComponent> = ({status,transactionNumber,amount}) => {
    
    const navigate = useNavigate()
    
    const goToDashboard = () => navigate(PROTECTED_ROUTES.DASHBOARD)
    const newPayment = () => {
        navigate(0)
    }
    
    const title = status ? "Thank you for your purchase": "Sorry something went wrong!"
    const message = status ? `Your payment of $${formatAmount(amount)} was successful. Transaction Number ${transactionNumber}.` : ``
    return(
        <Box style={{  justifyContent:"center",alignItems:"center",textAlign:'center'}}>
            <img src={SuccessPaymentLogo} width={"260px"}/>
            <Typography variant="h1" style={{color:"#333333",fontFamily:'NotoSans-Regular',fontWeight:600 ,backgroundColor:'transparent',fontSize:'16px',marginTop:'50px'}}>{title}</Typography>
            <br/>
            <Typography variant="body1" style={{color:"#333333",fontFamily:'NotoSans-Regular',fontWeight:200 ,backgroundColor:'transparent',fontSize:'14px',marginBottom:'50px'}}>{message}</Typography>
            
            <Button variant="text" style={{backgroundColor:COLORS.BRAND_PRIMARY,color:"#FFF", marginTop:'0px',textTransform:'capitalize'}} fullWidth disableRipple onClick={goToDashboard} >Back to Home</Button>
            <Button variant="text" style={{color:COLORS.BRAND_PRIMARY, marginTop:'15px',textTransform:'capitalize'}} fullWidth disableRipple onClick={newPayment} >New Payment</Button>
        </Box>
    )
}

export default ResponseTransactionComponent